// Type
//

@include rtl-only {
  .list-unstyled,
  .list-inline {
    padding-right: 0;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0;
    margin-left: $list-inline-padding;
  }
}
